
import Vue from 'vue'

import { userMapGetters } from '@/store/modules/userModule'

export default Vue.extend({
  name: 'Sites',
  components: {
    ImgNoAccess: () => import('@/components/images/ImgNoAccess.vue'),
  },
  computed: {
    ...userMapGetters(),
    hasAccess() {
      return this.userSites?.length !== 0
    },
  },
  data() {
    return {
      contactSupportLink: `mailto:${Vue.$appSettings.supportEmail}`,
      trialWebsiteLink: Vue.$appSettings.identityServerAuthority,
      selectedSite: null,
    }
  },
  methods: {
    isLoading(siteNo) {
      if (!this.selectedSite) return null

      return this.selectedSite === siteNo ? 'primary' : null
    },
  },
})
